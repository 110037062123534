.PressContentItem {
    text-decoration: none;
    color: inherit;
    max-height: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PressContentItem-ImageWrap {
    width: 200px;
    height: 262px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
   
}

.swiper-container {
    max-width: 1600px;
}

.PressContentItem-Image {
    position: absolute;
    display: block;
    width: 200px;
    height: 262px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.PressContentItem .PressContentItem-ImageWrap:before {
    position: absolute;
    content: '';
    display: inline;
    right: 15px;
    bottom: 10px;
    width: 25px;
    height: 25px;
    
    background-color: #E30611;
    border-radius: 50%;
    background-image: url('../../Icons/cart.svg');
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    z-index: 1;
}

.PressContentItem-Title {
    text-align: center;
    position: relative;
    font-family: "Lato Regular", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: #ffffff;
}

@media (min-width: 500px) {
    .PressContentItem_premium .PressContentItem-ImageWrap:before {
        width: 40px;
        height: 40px;
    }

}

@media (max-width: 800px) {
    .PressContentItem {
        /* width: 144px !important; */
        /* margin-right: 10px !important; */
    }

    .swiper-container {
        /* width: 100%; */
    }

    .PressContentItem-Image,
    .PressContentItem-ImageWrap {
        width: 144px;
        height: 188px;
    }
}

@media (max-width: 600px) {

    .PressContentItem {
        /* width: 130px !important; */
    }

    .PressContentItem-Image,
    .PressContentItem-ImageWrap {
        width: 130px;
        height: 170px;
    }
}


@media (min-width: 1200px) {

    .PressContentItem-ImageWrap {
        height: 262px;
        width: 200px;
    }

    .PressContentItem .PressContentItem-ImageWrap:before {
        width: 35px;
        height: 35px;
    }
}

