.PromoBannerItem {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.PromoBannerItem-Logo {
  flex: 1;
  background: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  min-width: 220px;
}

.PromoBannerItem-Logo svg {
  height: 30px;
}

.PromoBannerItem-Caption {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
  padding: 20px;
  font-family: 'Times New Roman', sans-serif;
  font-size: 24px;
  line-height: 1.2;
  flex: 2.5;
  background: #ea2323;
  color: #fff;
}

.PromoBannerItem-Caption_Long {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  flex: 3.5;
}

.PromoBannerItem-Caption_Light {
  background: #fff;
  color: #000;
}

.PromoBannerItem-ImageWrap {
  position: relative;
  flex: 5;
}

.PromoBannerItem-Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 960px) {
  .PromoBannerItem {
    flex-flow: row nowrap;
    height: 110px;
  }
}

@media only screen and (min-width: 1024px) {
  .PromoBannerItem:hover {
    width: calc(100% + 10px);
    height: 120px;
    transform: translate(-5px, -5px);
    transition: all .3s ease;
  }
}
