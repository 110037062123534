.Equalizer {
    width: 18px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Equalizer div {
    display: inline-block;
    background-color: #FF0000;
    width: 4px;
    border-radius: 2px;
}

.Equalizer div:nth-child(1), .Equalizer div:nth-child(3) {
    background-color: #FF0000;
    animation: .75s linear 300ms infinite normal equalizerPlay2;
}

.Equalizer div:nth-child(2) {
    background-color: #FF0000;
    animation: .75s linear 300ms infinite normal equalizerPlay1;
}
