.AddToHomeScreen {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: all;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  max-width: calc(100vw - 20px);
  margin: 10px;
  z-index: 101;
}

.AddToHomeScreen-Button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  flex-shrink: 0;
  pointer-events: all;
  width: 60px;
  height: 60px;
  background: #e30611;
  border-radius: 100%;
  cursor: pointer;
}

.AddToHomeScreen-Button > svg {
  color: #fff;
  width: 28px;
  height: 28px;
  z-index: 10;
}

.AddToHomeScreen-Button-Animate {
  animation: buttonAnimation 2s linear infinite;
}

.AddToHomeScreen-ButtonClose {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(5px, -5px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #e30611;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}

.AddToHomeScreen-ButtonClose > svg {
  width: 16px;
  height: 16px;
}

.AddToHomeScreen-Popup {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  pointer-events: all;
  max-width: 350px;
  margin-right: 14px;
  background: #fff;
  color: #000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 10px 30px 12px 10px;
  animation: popupAnimation .3s linear;
}

.AddToHomeScreen-Popup:after {
  content: "";
  position: absolute;
  top: 60%;
  left: calc(100% - 8px);
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
}

.AddToHomeScreen-PopupClose {
  position: absolute;
  top: 10px;
  right: 8px;
  cursor: pointer;
}

.AddToHomeScreen-PopupClose > svg {
  width: 24px;
  height: 24px;
}

.AddToHomeScreen-PopupCover {
  flex-shrink: 0;
  background: #333;
  width: 72px;
  height: 72px;
  margin-right: 5px;
  border-radius: 10px;
}

.AddToHomeScreen-PopupContent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.AddToHomeScreen-PopupText {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.1;
}

.AddToHomeScreen-PopupText {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.1;
  font-family: Arial, sans-serif;
}

.AddToHomeScreen-PopupText > svg {
  margin: 0 5px;
}

.AddToHomeScreen-PopupButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
  min-width: 95px;
  width: auto;
  height: 30px;
  background: #e30611;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .AddToHomeScreen-PopupText {
    font-size: 14px;
  }
}

@keyframes popupAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5) translateX(50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}

@keyframes buttonAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
