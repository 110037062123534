.ContentPage {
    display: flex;
    justify-content: center;
    padding: 0 20px;
}

.ContentPage-Content {
    margin-top: 93px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}

@media (max-width: 1200px) {
    .ContentPage-Content {
        margin-top: 70px;
    }
}

.ContentPage-Wrapper {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-CategoryLink-Btn_size_L {
    display: none;
}

.ContentPage-CategoryLink-Btn_size_S {
    background-image: url('../Icons/back.svg');
}

.ContentPage-AdditionalBar {
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
}

.ContentPage-ContentAdditionalTitle {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    color: #000;
    margin-bottom: 0;
    text-align: center;
    margin: 30px 0;
}

.ContentPage-Title {
    position: relative;
    margin-left: 11px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    display: flex;
    flex-direction: row;

}

.ContentPage-Image {
    float: none;
    width: 32vh !important;
    height: 35vh !important;
    margin-bottom: 10px;
}

.ContentPage-ContentName {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #000;
    margin-bottom: 20px;
}

.ContentPage-CategoryLink,
.ContentPage-CategoryLink:hover,
.ContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    z-index: 1;
    align-self: center;
    margin-right: 26px;
    background-image: url('../Icons/backBlack.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.ContentPage-Title:before {
    display: none;
}

.ContentPage-MainBar {
    width: 100%;
}

.ContentPage-ContentWrap{
    /* box-shadow: 0px 5px 15px rgb(0 0 0 / 25%); */
    border-radius: 10px;
    padding: 15px 40px 15px 15px;
    margin-top: -10px;
    box-shadow: none;
}


@media (max-width: 1200px) {

    .ContentPage-Image {
        width: 100% !important;

    }

    .ContentPage-Title {
        margin-left: 0;
    }

}

@media (max-width: 800px) {
    .ContentPage-Wrapper {
        width: 100%;
        max-width: 768px;
        flex-direction: column;
    }

    .ContentPage-Title {
        margin-left: -8px;
    }

    .ContentPage-ContentWrap {
        margin: 22px;
        padding: 20px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
        width: auto;
    }

    .ContentPage-Image {
        width: 98% !important;
        align-items: center;
        margin: auto;
        margin-bottom: 20px;
        border-radius: 10px !important;
    }

    .ContentPage-AdditionalBar {
        margin-top: 0;
        max-width: 100%;
        justify-content: center;
        display: flex;
    }


}

@media (max-width: 400px) {

    .ContentPage {
        padding: 0;
    }

    .ContentPage-Wrapper {
        width: 100%;

    }

    .ContentPage-ContentWrap {
        margin: 0;
        padding: 20px 20px 0 20px;
    }

    .ContentPage-Image {
        width: 280px !important;
        height: 280px !important;
        border-radius: 10px !important;
    }

    .ContentPage-Title {
        margin-left: 12px;
    }

    .ContentPage-ContentAdditionalTitle {
        display: block;
    }
}


@media (min-width: 720px) {

    .ContentPage-ContentName {
        font-size: 32px;
    }

    .ContentPage-Video {
        width: 96%;
        margin: 0 2% 20px;
    }

    .ContentPage-ContentTextWrap {
        width: 100%;
        position: relative;
        background-color: transparent;
    }



    .ContentPage-CategoryLink-Btn {
        height: 80px;
        width: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px 50px;
    }

    .ContentPage-CategoryLink-Btn_size_L {
        display: block;
    }

    .ContentPage-CategoryLink-Btn .ContentPage-CategoryLink-Btn_size_S {
        display: none;
    }

     .ContentPage-ContentAdditionalTitle {
         display: none;
     }
}





@media (min-width: 1200px) {


    .ContentPage-CategoryLink {
        display: none;
    }


    .ContentPage-Image {
        width: 320px !important;
        height: 320px !important;
        float: left;
        margin: 10px 30px 30px 0;
    }

    .ContentPage-Image {

        float: left;
        margin: 0px 30px 30px 0;
    }


    .ContentPage-ContentTextWrap {
        border-radius: 0;
        width: auto;
        position: relative;
        background-color: transparent;
        box-shadow: none;
    }



     .ContentPage-ContentAdditionalTitle {
         display: none;
     }

      .ContentPage-type_pressa-ru-ua-pdf {
          color: #fff;
      }

      .ContentPage-Title:before {
            fill: #fff;
            transform: translateY(-50%);
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 20px 5px 0px 5px;
      }

      .ContentPage-type_pressa-ru-ua-pdf:before {
          background-image: url('../Icons/magazinesWhite.svg');
      }

      .ContentPage-type_pro-sport:before {
          background-image: url('../Icons/sport.svg');
      }

      .ContentPage-type_pro-avtomobili:before {
          background-image: url('../Icons/auto.svg');
      }

      .ContentPage-type_pro-stil:before {
          background-image: url('../Icons/style.svg');
      }

      .ContentPage-type_pro-gadzhety:before {
          background-image: url('../Icons/gadgets.svg');
      }

      .ContentPage-type_pro-otdykh:before {
          background-image: url('../Icons/relaxation.svg');
      }

      .ContentPage-type_pro-kosmos:before {
          background-image: url('../Icons/space.svg');
      }

      .ContentPage-type_cookery:before {
          background-image: url('../Icons/food.svg');
      }

}

.ContentPage-PromoBanner .PromoBannerItem-Logo {
    min-width: 140px;
}

.ContentPage-PromoBanner .PromoBannerItem-Logo svg {
    height: 18px;
}

.ContentPage-PromoBanner .PromoBannerItem-Caption {
    font-size: 22px;
}

.ContentPage-PromoBanner .PromoBannerItem-Caption.PromoBannerItem-Caption_Long {
    font-size: 22px;
}
